import React from 'react'
import 'twin.macro'

export default function ServicesDetail({data,page}){
    let finalData= data?.schema_json;  
    let content= {
        heading:"",
        sub_heading:"",
        description:"",
        cards:[
            {
                heading:"",
                description:""
            }
        ],
       ...finalData
  }

  let cardsData = content.cards.map((card, i) => (
    // <div tw="flex flex-col justify-center items-center"  key={i}>
    //     <svg tw="flex-shrink-0 h-6 w-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
    //     <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
    //     </svg>
    //     <div tw="mt-2 text-lg text-center leading-6 font-medium text-gray-900">
    //         {card.heading}
    //     </div>
    //     <div tw="mt-2 text-base text-gray-500 text-center">
    //         {card.description}
    //     </div>
    // </div>
    <div tw="flex" key={i}>
          <div tw="flex-shrink-0">
            <div tw="flex items-center justify-center h-12 w-12 rounded-md bg-secondary-900 text-white p-3">
            <svg xmlns="http://www.w3.org/2000/svg" xmlns="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" width="594.149px" height="594.149px" viewBox="0 0 594.149 594.149" fill= "white" xml="preserve">
                <g id="done-all">
                    <path d="M448.8,161.925l-35.7-35.7l-160.65,160.65l35.7,35.7L448.8,161.925z M555.899,126.225l-267.75,270.3l-107.1-107.1    l-35.7,35.7l142.8,142.8l306-306L555.899,126.225z M0,325.125l142.8,142.8l35.7-35.7l-142.8-142.8L0,325.125z"/>
                </g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g>
            </svg>
            </div>
          </div>
          <div tw="ml-4">
           {card.heading && <div tw="text-lg leading-6 font-medium text-gray-900 mb-2">{card.heading}</div>} 
            <div tw='text-base text-gray-500'>{card.description}</div>
          </div>
        </div>
    ))

    return (
        <div tw="bg-white w-full">
            <div tw="max-w-7xl mx-auto py-4 px-4 sm:px-6 lg:py-6 lg:px-8 mb-6">
                <div tw="max-w-3xl mx-auto text-center">
                <h3 tw="text-xl font-semibold text-gray-900">{content.sub_heading}</h3>
                <h2 tw="text-3xl font-extrabold text-gray-900">{content.heading}</h2>
                <p tw="mt-4 text-lg text-gray-500">{content.description}</p>
                </div>
                <dl tw="mt-12 space-y-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 lg:grid-cols-3 lg:gap-x-12">
                    {cardsData}
                </dl>
            </div>
            </div>

    )
}
